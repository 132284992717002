import React, { useEffect, useState, useRef } from 'react';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import * as Constants from '../utils/constants';
import LineButtons from './LineButtons';
import ApiService from '../utils/ApiService';
import ModelAlert from './ModalAlert';
import { dateFormattedDatagrid } from '../utils/utils';
import { generateAndDownloadPDF } from '../components/InvoiceGenerator';
import { Box } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Stack from '@mui/material/Stack';
import SearchBar from './SearchBar';

const OrdersDataGrid = ({ orders, handleCellClick, orderOptions, paginationModel, updatePaginationModel, rowCount, loading}) => {
    const [selectionModel, setSelectionModel] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('error');
    const [selectedOption, setSelectedOption] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const hasMounted = useRef(false);
    const optionsFilter = { 'Todas': 'all', 'Em Andamento': 'ongoing', 'Finalizado': 'completed', 'Novas': 'new' };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    useEffect(() => {
        if (hasMounted.current) {
            const newModel = {}

            if (searchTerm !== undefined) {
                newModel.search = searchTerm;
            }

            if (selectedOption !== 'all' || selectedOption !== undefined) {
                newModel.filter = getFilterOptions(selectedOption);
            }

            updatePaginationModel(newModel);
        } else {
            hasMounted.current = true;
        }
    }, [searchTerm, selectedOption]);

    function getFilterOptions(option) {
        let filter_order = [];
        let filter_shipping = [];
        switch (option) {
            case 'ongoing':
                filter_order = ['paid'];
                filter_shipping = ['shipped', 'not_delivered', 'returned_ongoing'];
                break;
            case 'completed':
                filter_order = ['paid', 'completed', 'cancelled'];
                filter_shipping = ['delivered', 'cancelled', 'returned'];
                break;
            case 'new':
                filter_order = ['paid', 'new',  'processing', 'pending'];
                filter_shipping = ['ready_to_ship', 'custom_shipping', 'paid', 'pending', null];
                break;
            default:
                console.log('Status não encontrado: ' + option);
                break;
        }
        return JSON.stringify({order: filter_order, shipping: filter_shipping});
    }

    const titleCase = (str) => {
        var splitStr = str.toLowerCase().split(' ');
        let upper = false
        for (var i = 0; i < splitStr.length; i++) {
            if (splitStr[i].charAt(0) === '(')
                upper = true;

            if (upper) {
                if (splitStr[i].charAt(splitStr[i].length - 1) === ')')
                    upper = false;
                splitStr[i] = splitStr[i].toUpperCase();
            } else {
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
        }

        return splitStr.join(' ');
    }

    useEffect(() => {
        for (let i = 0; i < orders.length; i++) {
            setStatusStr(orders[i]);
        }
    }), [orders];

    function setStatusStr(order) {
        switch (order.order_status) {
            case 'paid':
                switch (order.shipping_status) {
                    case 'shipped':
                        order.status_color = 'ongoing';
                        order.status = 'Enviado';
                        break;
                    case 'ready_to_ship':
                        order.status_color = 'new';
                        order.status = 'Etiqueta pronta';
                        break;
                    case 'custom_shipping':
                        order.status_color = 'new';
                        order.status = 'Envio a combinar';
                        break;
                    case null:
                        order.status_color = 'new';
                        order.status = 'Envio a combinar';
                        break;
                    case 'not_delivered':
                        order.status_color = 'ongoing';
                        order.status = 'Problemas na entrega';
                        break;
                    case 'delivered':
                        order.status_color = 'completed';
                        order.status = 'Entregue';
                        break;
                    case 'returned_ongoing':
                        order.status_color = 'ongoing';
                        order.status = 'Em Devolução';
                        break;
                    case 'returned':
                        order.status_color = 'completed';
                        order.status = 'Devolvido';
                        break;
                    case 'paid':
                        order.status_color = 'new';
                        order.status = 'Envio a combinar';
                        break;
                    case 'cancelled':
                        order.status_color = 'completed';
                        order.status = 'Envio cancelado';
                        break;
                    case 'pending':
                        order.status_color = 'new';
                        order.status = 'Etiqueta pendente';
                        break;
                    default:
                        order.status_color = 'new';
                        console.log('Status não encontrado: ' + order.order_status, order.shipping_status);
                        order.status = "Status desconhecido";
                        break;
                }
                break;
            case 'completed':
                order.status_color = 'completed';
                order.status = 'Finalizado';
                break;
            case 'new':
                order.status_color = 'new';
                order.status = 'Novo';
                break;
            case 'cancelled':
                order.status_color = 'completed';
                order.status = 'Cancelado';
                break;
            case 'processing':
                order.status_color = 'new';
                order.status = 'Pagamento em análise';
                break;
            case 'pending':
                order.status_color = 'new';
                order.status = 'Pagamento Pendente';
                break;
            default:
                order.status_color = 'new';
                console.log('Status não encontrado: ' + order.order_status);
                order.status = 'Status desconhecido';
                break;
        }
    }

    const smallColumns = [
        {
            field: 'marketplace_id',
            headerName: '',
            sortable: false,
            align: 'center',
            renderCell: (params) => (<img src={Constants.ICON_DIR + params.value + ".ico"} width="30" alt="marketplace" />),
            stopClickPropagation: true,
            flex: 5,
        },
        { field: 'items_str', headerName: 'Produtos', flex: 90, sortable: false },
    ];

    const largeColumns = [
        {
            field: 'marketplace_id',
            headerName: '',
            sortable: false,
            align: 'center',
            renderCell: (params) => (<img src={Constants.ICON_DIR + params.value + ".ico"} width="30" alt="marketplace" />),
            stopClickPropagation: true,
            flex: 0.1,
        },
        {
            field: 'order_date',
            headerName: 'Data',
            flex: 1.6,
            sortable: true,
            fontSize: 2,
            align: 'center',
            autoSizeRowsMode: 'AllCells',
            WrapMode: true,
            renderCell: (params) => { return dateFormattedDatagrid(params.value, true) },
        },
        {
            field: 'items_str', headerName: 'Produtos', flex: 10, sortable: true,
            valueFormatter: (params) => titleCase(params.value),
        },
        { field: 'buyer_name', headerName: 'Comprador', flex: 3.5, sortable: true, },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1.5,
            sortable: false,
        },
    ];

    const [columns, setColumns] = useState(window.innerWidth <= 720 ? [...smallColumns] : [...largeColumns]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 720 && columns.length > smallColumns.length)
                setColumns(smallColumns);
            else if (window.innerWidth > 720 && columns.length < largeColumns.length)
                setColumns(largeColumns);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [columns]);

    const handleSelectionChange = (newSelection) => {
        setSelectionModel(newSelection);
    }

    const handleOpenLink = (filename) => {
        const win = window.open(Constants.DOCUMENTS_ENDPOINT + filename, '_blank');
        win?.focus();
    }

    const handleButtonClick = async (id) => {
        if (selectionModel.length === 0) {
            return;
        }
        await ApiService.post('/orders_action', { id, orders: selectionModel }).then((response) => {
            if (response.failed) {
                setAlertSeverity('error');
                setAlertMessage(response.message);
            } else {
                setSelectionModel([]);
                if (id === 4) { // TODO: get from definitions, 4 = generate_report
                    generateAndDownloadPDF(response.data);
                } else {
                    handleOpenLink(response.data);
                }
            }
        }).catch((error) => {
            setAlertSeverity('error');
            setAlertMessage(error.message);
        });
    };

    const handleCloseAlert = () => {
        setAlertMessage('');
        if (alertSeverity !== 'error')
            setSelectionModel([]);
        setAlertSeverity('error');
    }

    const getCellClassName = (params) => {
        if (window.innerWidth > 720) {
            if (params.field === 'status') {
                return Constants.COLORS_STATUS[params.row.status_color] ? Constants.COLORS_STATUS[params.row.status_color] : Constants.COLORS_STATUS['default'];
            }
        } else {
            return Constants.COLORS_STATUS[params.row.status_color] ? Constants.COLORS_STATUS[params.row.status_color] : Constants.COLORS_STATUS['default'];
        }
    };

    const handleSortChange = (sortModel) => {
        if (sortModel && sortModel.length > 0){
            updatePaginationModel({ orderBy:JSON.stringify(sortModel[0]) });
        }
    };

    return (
        <React.Fragment>
            <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                <Box display="flex">
                    <SearchBar inputValue={searchTerm} setInputValue={setSearchTerm}></SearchBar>
                    {orderOptions ? <LineButtons options={orderOptions} disabled={selectionModel.length === 0} handleButtonClick={handleButtonClick}></LineButtons> : null}
                </Box>
                <Box sx={{
                    display: {
                        xs: 'none',
                        sm: 'block',
                    },
                }}>
                    <FormControl style={{ minWidth: 200, minHeight: 40 }}>
                        <InputLabel id="select-filter">Filtrar</InputLabel>
                        <Select
                            labelId="select-filter"
                            value={selectedOption}
                            label='Filtrar'
                            onChange={handleSelectChange}
                            style={{
                                minWidth: 200, height: 40,
                                display: {
                                    xs: 'none',
                                    sm: 'block',
                                },
                            }}
                        >
                            {Object.entries(optionsFilter).map(([label, value]) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Stack>
            {orders ?
                <DataGrid sx={{ mt: 2 }}
                    autoHeight={true}
                    checkboxSelection
                    checkboxSelectionVisibleOnly={true} // check only ids from current page, TODO: need fix on this prop
                    disableRowSelectionOnClick={true} // disable row selection, only checkbox selection
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={handleSelectionChange}
                    localeText={{ ...ptBR.components.MuiDataGrid.defaultProps.localeText, footerRowSelected: () => '' }}
                    columns={columns}
                    rows={orders}
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnSelector
                    pageSizeOptions={[10, 25, 50]}
                    onCellClick={handleCellClick}
                    getCellClassName={getCellClassName}
                    rowCount={rowCount}
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={(newPaginationModel) => updatePaginationModel(newPaginationModel)}
                    loading={loading}
                    sortingOrder={['asc', 'desc']}
                    sortingMode="server"
                    onSortModelChange={(sortModel) => handleSortChange(sortModel)}
                /> : <CircularProgress />}
            <ModelAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} closeButton={handleCloseAlert} alertSeverity={alertSeverity} />
        </React.Fragment>
    )
}

export default OrdersDataGrid;
